.footer {

  font-size: 12px;

  margin-bottom: 0 !important;
  padding-bottom: 50px !important;

  .row {
    div:last-child {
      font-size: $font-size-base;

      @media (max-width: $screen-md) {
        margin-top: 40px;
      }
    }
  }

  p {
    margin: 0;
    strong {

      font-family: $font-family-sans-serif;
      color: inherit;

      a {
        border-bottom: 3px solid $mv-green1;
        text-decoration: none;
        //
        //&:hover {
        //  text-decoration: none;
        //}
      }
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
  }
}

@media (min-width: $screen-lg-min) {

  .footer {
    font-size: $font-size-base;
  }
}