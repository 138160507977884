.icon-list li {
  padding: 2px 0 0 20px;
  display: block;
  position: relative;
}

.icon-list li:before {
  font-family: 'Glyphicons Halflings';
  position: absolute;
  left: 0;
  //top: 3px;
  font-size: 90%
}

.calendar:before {
  content: '\e109';
}

.google-maps:before {
  content: '\e062';
}

.group:before {
  content: '\e044';
}

.clock:before {
  content: '\e023';
}

.comments:before {
  content: '\e111';
}