.boards {

  .row {

    .board {

      margin-bottom: 75px;

      .box {
        border-bottom: solid 4px $mv-blue;

        p {
          margin: 0;
        }
      }

    }
  }
}