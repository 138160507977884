.home {

  .navbar-default {
    //background-color: $mv-blue;
    //border-color: darken($mv-blue, 8%);
  }

  #header-div {
    display: table;
    width: 100%;
    height: calc(100vh - 81px);
    margin-top: -22px;
    position: relative;
    background-color: $mv-blue;
    //background-image: url('https://bootstrap-themes.github.io/marketing/assets/img/startup-1.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1; // fix for news-container before and after

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: darken($mv-blue, 20%);
      opacity: .8;
    }

    .col-top, .col-middle {
      width: 100%;
      text-align: center;
      position: relative;

      img {
        margin: auto;
      }

      h1 {
        font-size: 45px;
        font-weight: 500;
        color: $mv-white;
      }

      /* Small devices (tablets, 768px and up) */
      @media (min-width: $screen-sm-min) {
        text-align: left;
        position: absolute;
        width: ($container-sm - $grid-gutter-width) / 2;
      }

      /* Medium devices (desktops, 992px and up) */
      @media (min-width: $screen-md-min) {
        width: ($container-md - $grid-gutter-width) / 2;
      }

      /* Large devices (large desktops, 1200px and up) */
      @media (min-width: $screen-lg-min) {
        width: ($container-lg - $grid-gutter-width) / 2;
      }
    }

    .col-top {
      @media (min-width: $screen-sm-min) {
        top: 20%;
        right: 50%;
        padding-right: 20px;
      }
    }

    .col-middle {
      @media (min-width: $screen-sm-min) {
        top: 50%;
        left: 50%;

        //transform: translate(-50%, -50%);
        transform: translateY(-50%);
      }

    }
  }

  .news-container {
    position: relative;
    margin: 30px;
    padding: 25px 20px;
    text-align: center;
    background: #fff;
    border: 1px solid #ccc;
    display: inline-block;

    img {
      position: relative;
      z-index: 12;
      margin: 0 auto;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 3px;
      left: 0;
      background: #fff;
      z-index: -1;
      -webkit-transform: rotateZ(3deg);
      -moz-transform: rotateZ(3deg);
      -ms-transform: rotateZ(3deg);
      -o-transform: rotateZ(3deg);
      transform: rotateZ(3deg);
      border: 1px solid #ccc;
    }

    &::after {
      top: 5px;
      -webkit-transform: rotateZ(-2deg);
      -moz-transform: rotateZ(-2deg);
      -ms-transform: rotateZ(-2deg);
      -o-transform: rotateZ(-2deg);
      transform: rotateZ(-2deg);
    }
  }

  .events {
    .row {

      .col {

        &:nth-child(1) h3 {
          color: $mv-green1;
        }

        &:nth-child(2) h3 {
          color: $mv-red;
        }

        &:nth-child(3) h3 {
          color: $mv-blue;
        }

        &:nth-child(4) h3 {
          color: $mv-green2;
        }
      }
    }
  }

  .albums {
    h2 {
      color: $mv-black;
      margin-bottom: 75px;
    }
  }

  ////>= sm
  //@media (min-width: $screen-sm-min) {
  //  .e {
  //    max-width: 740px;
  //  }
  //}
  //
  //// >= md
  //@media (min-width: $screen-md-min) {
  //  .e {
  //    max-width: 880px;
  //  }
  //}

  h2 {
    text-align: center;
  }
}
