.module__testimonials {

  //text-align: center;

  //img {
  //  border: solid 10px $bah-font-invert;
  //}

  .carousel {
    //min-height: 250px;
  }

  .text {
    border-left: solid 3px $mv-gray;
    padding-left: 50px;

    h3 {
      margin-top: 10px;
    }
  }

  .carousel-nav {

    text-align: center;
    margin-top: 50px;

    ol {

      // RESET BOOTSTRAP carousel-indicators DEFAULTS
      // carousel-indicators class is needed for js highlighting
      position: relative;
      left: auto;
      width: auto;
      bottom: auto;

      margin: 0;
      padding: 0;
      display: inline-block;

      li {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin: 1px;
        border-radius: 10px;
        cursor: pointer;
        border: solid 1px $mv-gray;
        background-color: $mv-gray;

        &.active {
          border: solid 1px $mv-red;
          background-color: $mv-red;
        }
      }
    }

    .carousel-arrows {
      width: 18px;
      height: 18px;
      background: $mv-green1;
      border-radius: 50%;
      color: $mv-white;
      display: inline-block;
      text-align: center;
      font-size: 0.8em;
      line-height: 19px;

      &.left {
        margin-right: 60px;
      }

      &.right {
        margin-left: 60px;
      }
    }
  }
  //
  //.carousel-control {
  //  &.right, &.left {
  //    background: none;
  //  }
  //
  //  width: inherit;
  //  color: $mv-red;
  //}
  //
  //.carousel-control .glyphicon-menu-left {
  //  border: solid 2px $mv-red;
  //  margin-left: 0;
  //}
  //
  //.carousel-control .glyphicon-menu-right {
  //  border: solid 2px $mv-red;
  //
  //  // TODO
  //  //margin-left: -$carousel-control-size;
  //}
}