.module__columns, .module__columns-2, .module__columns-3 {

  ul {
    list-style: none;

    li::before {
      content: '';
      width: .6em;
      height: .6em;
      //line-height: 1em;
      background-color: $mv-green2;
      float: left;
      border-radius: 50%;
      margin: .4em 0 0 -1.3em;
    }
  }
}