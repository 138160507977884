/* merriweather-regular - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/merriweather-v19-latin/merriweather-v19-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Regular'), local('Merriweather-Regular'),
  url('../fonts/merriweather-v19-latin/merriweather-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/merriweather-v19-latin/merriweather-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/merriweather-v19-latin/merriweather-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/merriweather-v19-latin/merriweather-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/merriweather-v19-latin/merriweather-v19-latin-regular.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-700 - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/merriweather-v19-latin/merriweather-v19-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Bold'), local('Merriweather-Bold'),
  url('../fonts/merriweather-v19-latin/merriweather-v19-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/merriweather-v19-latin/merriweather-v19-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/merriweather-v19-latin/merriweather-v19-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/merriweather-v19-latin/merriweather-v19-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/merriweather-v19-latin/merriweather-v19-latin-700.svg#Merriweather') format('svg'); /* Legacy iOS */
}
/* merriweather-700italic - latin */
@font-face {
  font-family: 'Merriweather';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/merriweather-v19-latin/merriweather-v19-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Merriweather Bold Italic'), local('Merriweather-BoldItalic'),
  url('../fonts/merriweather-v19-latin/merriweather-v19-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/merriweather-v19-latin/merriweather-v19-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/merriweather-v19-latin/merriweather-v19-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/merriweather-v19-latin/merriweather-v19-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/merriweather-v19-latin/merriweather-v19-latin-700italic.svg#Merriweather') format('svg'); /* Legacy iOS */
}

/* oswald-regular - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/oswald-v16-latin/oswald-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Oswald Regular'), local('Oswald-Regular'),
  url('../fonts/oswald-v16-latin/oswald-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/oswald-v16-latin/oswald-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/oswald-v16-latin/oswald-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/oswald-v16-latin/oswald-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/oswald-v16-latin/oswald-v16-latin-regular.svg#Oswald') format('svg'); /* Legacy iOS */
}
/* oswald-700 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/oswald-v16-latin/oswald-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Oswald Bold'), local('Oswald-Bold'),
  url('../fonts/oswald-v16-latin/oswald-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/oswald-v16-latin/oswald-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/oswald-v16-latin/oswald-v16-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/oswald-v16-latin/oswald-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/oswald-v16-latin/oswald-v16-latin-700.svg#Oswald') format('svg'); /* Legacy iOS */
}