.flexthis {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  //align-items: stretch;
}

.flexthis [class*='col-'] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  //align-self: stretch;
}

// fix for Safari: http://stackoverflow.com/a/36476586/2455679
.flexthis.container:before,
.flexthis.container:after,
.flexthis.row:before,
.flexthis.row:after {
  content: normal; // IE doesn't support `initial`
}