$mv-blue: #3296BE;
$mv-red: #CE1820;
$mv-green1: #7CB938;
$mv-green2: #008E3D;

$mv-black: #000105;
$mv-white: #fff;
$mv-gray: #ccc;

.blue, .blue:hover {
  color: $mv-blue;
}

.red, .red:hover {
  color: $mv-red;
}

.green1, .green1:hover {
  color: $mv-green1;
}

.green2, .green2:hover {
  color: $mv-green2;
}

// Bootstrap
$icon-font-path: '/assets/fonts/bootstrap/';

$border-radius-base: 3px;

//$navbar-default-bg: transparent;
$navbar-height: 80px;
$navbar-default-brand-hover-bg: transparent;
$navbar-default-color: $mv-white;
$navbar-default-link-color: $mv-white;
$navbar-default-bg: $mv-black;
$navbar-default-border: none;
//$navbar-default-link-hover-color: darken($mv-white, 20%);
$navbar-default-link-hover-color: $mv-green1;
$navbar-default-link-active-color: $mv-green1;
$navbar-default-link-active-bg: transparent;
$navbar-default-toggle-icon-bar-bg: $mv-white;
//$navbar-default-toggle-hover-bg: darken($mv-blue, 5%);
$navbar-default-toggle-hover-bg: #2c2c2c;

$font-size-base: 15px;
$link-color: $mv-green1;

$font-family-sans-serif: 'Oswald', sans-serif;
$font-family-serif: 'Merriweather', serif;

$font-family-base: $font-family-serif;
$headings-font-family: $font-family-sans-serif;

//font-family:
//

$font-size-h1: floor(($font-size-base * 2.2));
$font-size-h2: floor(($font-size-base * 1.8));
$font-size-h3: ceil(($font-size-base * 1.2));
$font-size-h4: ceil(($font-size-base * 1));
$font-size-h5: ceil(($font-size-base * 0.9));
$font-size-h6: ceil(($font-size-base * 0.85));

$btn-primary-bg: $mv-green1;

@import "../bower_components/bootstrap-sass/assets/stylesheets/bootstrap";
//@import "bootstrap";

// cookie banner
$ka-cookie-bg: $mv-blue;
$ka-cookie-color: $mv-white;
$ka-cookie-link-color: $mv-white;
//$ka-cookie-link-color-hover: $w-red;
$ka-cookie-btn-bg: $mv-green1;
$ka-cookie-btn-color: $mv-white;

// cookie approval
@import "../website/site/plugins/cookie/cookie.scss";

.navbar-default {
  font-family: $font-family-sans-serif;
}

@import "board";
@import "footer";
@import "image";
@import "home";
@import "gallery";

@import "fonts";

@import "../website/site/modules/modules";

@import "grid-cards";

[class*='col-'] > h2:first-child,
[class*='col-'] > h3:first-child,
[class*='col-'] > h4:first-child,
[class*='col-'] > h5:first-child {
  margin-top: 0;
  //color: yellow;
}

figure {
  img {
    @extend .img-responsive;
    margin: auto;
    //@extend .img-fluid;
  }

  // wird z.B. für Copyright verwendet
  figcaption {
    font-size: 12px;
    color: $mv-black;
    text-align: center;
    font-family: $font-family-sans-serif;
  }
}

.btn {
  padding: 6px 25px;

  &.btn-primary {
    font-weight: 700;
    font-style: italic;
  }
}

p {
  strong {
    color: $mv-blue;
  }
}


.navbar {

  font-size: 20px;

  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    font-size: 18px;
  }

  .navbar-brand {

    img {
      width: 100px;
      margin-top: -19px;
    }
  }

  .navbar-nav {

    /* Small devices (tablets, 768px and up) */
    @media (min-width: $screen-sm-min) {
      li:first-child {
        a {
          padding-left: 0;
        }
      }
    }

    .active {
      a span {
        //text-decoration: underline;
        border-bottom: 3px solid $mv-green1;
      }
    }
  }
}

.block-black {
  background-color: $mv-black;
  margin-top: 75px;
  margin-bottom: 75px;
  padding-top: 75px;
  padding-bottom: 75px;
  color: $mv-white;

  h2 {
    //font-size: floor(($font-size-base * 2.15));
    color: $mv-white;
  }

  a {
    color: $mv-white;
  }
}

.block-white {
  margin-top: 75px;
  //padding: 0 30px 0 30px;
}

h1, h2, h3, h4, h5 {
  font-weight: 700;
}

h1 {
  color: $mv-green1;
  margin: 50px 0;
}

h2 {
  color: $mv-red;
  margin: 20px 0;
}

hr {
  border: solid 2px $mv-green1;
  width: 20%;
  margin-top: 75px;
  margin-bottom: 75px;

  &:last-child {
    display: none;
  }
}

p {
  margin: 20px 0;
}

.features {

  margin-top: 75px;
  //margin-bottom: 50px;

  .featured-list {

    list-style: none;
    padding: 0;

    li {
      position: relative;
      padding-left: 140px;
      margin-bottom: 50px;

      h3 {
        font-size: 24px;
      }

      p {
        margin-top: 5px;
        margin-bottom: 5px;
      }

      .link {
        font-weight: 700;
        font-style: italic;
      }
    }

  }

  .featured-list-icon {
    height: 120px;
    width: 120px;
    position: absolute;
    top: 0;
    left: 0;
  }

}

.albums {
  .row {
    .album {
      margin-bottom: 50px;

      img {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
        position: relative;

        &:hover {
          -webkit-filter: none;
          filter: none;
        }
      }

      .details {
        padding: 20px 0;

        h3 {
          margin: 0;
        }

        p {
          margin: 0;
          padding: 0;
        }
      }

      &:nth-child(1n) .box {
        border-bottom: solid 4px $mv-green1;
      }

      &:nth-child(2n) .box {
        border-bottom: solid 4px $mv-red;
      }

      &:nth-child(3n) .box {
        border-bottom: solid 4px $mv-blue;
      }

      &:nth-child(4n-7) .box {
        border-bottom: solid 4px $mv-green2;
      }

    }
  }
}

// uniform
.uniform__potty {
  position: absolute;
  left: -9999px;
}

/// photoswipe ///
$pswp__show-hide-transition-duration: 333ms !default;
$pswp__controls-transition-duration: 333ms !default;
$pswp__background-color: #000 !default;
$pswp__placeholder-color: #222 !default;
$pswp__box-sizing-border-box: true !default; // disable .pswp * { box-sizing:border-box } (in case you already have it in your site css)
$pswp__root-z-index: 1500 !default;
$pswp__assets-path: '../../assets/images/photoswipe/' !default; // path to skin assets folder (preloader, PNG and SVG sprite)
$pswp__error-text-color: #CCC !default; // "Image not loaded" text color
$pswp__include-minimal-style: true !default;

@import "../bower_components/photoswipe/src/css/main";
@import "../bower_components/photoswipe/src/css/default-skin/default-skin";


